<template>
  <!-- 系统设置 - 资讯公告-详情 -->
  <div class="pageContol aeAdvisoryNotice">
    <div class="framePage">
      <!-- 面包屑 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="cancel">系统设置</a>
          <i class="el-icon-arrow-right"></i>
          <a href="javascript:;" class="cur-a"> {{$route.query.stu == 'edit'? '修改':$route.query.stu == 'look'?'查看':'新增'}}通知</a>
        </span>
      </div>
      <!-- 编辑内容 -->
      <div class="framePage-body">
        <el-form
          ref="retrievalData"
          :rules="rules"
          :model="retrievalData"
          label-width="120px"
          :disabled="$route.query.stu == 'look'"
        >
          <el-form-item label="通知标题" prop="noticeTitle">
            <el-input
              size="small"
              placeholder="请输入通知标题"
              maxlength="20"
              show-word-limit
              v-model="retrievalData.noticeTitle"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="通知内容"
            prop="noticeContent"
            class="noticeContent"
          >
            <el-input
              size="small"
              type="textarea"
              maxlength="70"
              show-word-limit
              placeholder="请输入通知内容"
              v-model="retrievalData.noticeContent"
            ></el-input>
          </el-form-item>
          <el-form-item label="发送时间" prop="noticeTime">
            <el-date-picker
              v-model="retrievalData.noticeTime"
              size="small"
              type="datetime"
              placeholder="选择日期时间"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
            >
            </el-date-picker>
            <p style="color:red">注意:发送时间请选择到小时,选择分钟无效。</p>
          </el-form-item>
          <el-form-item label="通知范围" prop="noticeType">
            <el-select
              size="small"
              v-model="retrievalData.noticeType"
              clearable
              placeholder="请选择通知范围"
              @change="noticeTypeChange"
            >
              <el-option
                v-for="item in noticeTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="指定人群"
            prop="noticeParam"
            class="fileNameUpload"
            v-if="retrievalData.noticeType == '20'"
          >
            <el-upload
              v-model="retrievalData.noticeParam"
              v-if="!retrievalData.fileName"
              class="upload-demo upload-btn"
              :action="actionUrl"
              :on-error="handleError"
              :on-success="handleSuccess"
              :on-change="uploadChange"
              :show-file-list="false"
              :auto-upload="false"
            >
              <el-button size="small" class="bgc-bv">导入推送学员</el-button>
            </el-upload>
            <span style="color: #f46173" v-else>
              {{ retrievalData.fileName }}
              <a @click="reomveExl" style="color: #5bb5ff">删除</a>
            </span>
            <el-button
              type="text"
              @click="downTemplate"
              style="margin-left: 60px"
              >下载模版</el-button
            >
          </el-form-item>
          <el-form-item
            label="班级名称"
            prop="noticeParam"
            v-if="retrievalData.noticeType == '30'"
          >
            <el-select
              v-model="retrievalData.noticeParam"
              placeholder="请选择"
              remote
              size="small"
              clearable
              :remote-method="superProjectSelect"
            >
              <template>
                <div class="select-header">
                  <span
                    style="
                      color: #8492a6;
                      float: left;
                      font-size: 13px;
                      width: 150px;
                    "
                    >班级编号</span
                  >
                  <span
                    style="
                      color: #8492a6;
                      float: left;
                      font-size: 13px;
                      margin-left: 50px;
                      width: 250px;
                    "
                    >班级名称</span
                  >
                </div>
                <div class="select-header">
                  <el-input
                    v-model="seaProjectCode"
                    v-on:input="superProjectSelect"
                    type="text"
                    size="small"
                    placeholder="班级编号"
                    clearable
                    style="width: 150px"
                  />
                  <el-input
                    v-model="seaProjectName"
                    v-on:input="superProjectSelect"
                    type="text"
                    size="small"
                    placeholder="请输入班级名称"
                    clearable
                    style="margin-left: 50px; width: 250px"
                  />
                </div>
              </template>
              <el-option
                v-for="(item, index) in projectList"
                :key="index"
                :label="item.projectName"
                :value="item.noticeParam"
              >
                <span style="width: 150px; font-size: 13px">{{
                  item.projectCode
                }}</span>
                <span
                  style="width: 250px; margin-left: 50px; font-size: 13px"
                  >{{ item.projectName }}</span
                >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="机构名称"
            prop="noticeParam"
            v-if="retrievalData.noticeType == '40'"
          >
            <el-select
              size="small"
              v-model="retrievalData.noticeParam"
              remote
              :remote-method="getCompanyList"
              filterable
              clearable
              placeholder="请至少输入两个字搜索"
              style="width: 100%"
            >
              <el-option
                v-for="item in CompanyList"
                :key="item.compId"
                :label="item.compName"
                :value="item.compId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="点击通知打开" prop="jumpType">
            <el-radio-group
              v-model="retrievalData.jumpType"
              @change="jumpTypeChange"
            >
              <el-radio label="10">应用</el-radio>
              <el-radio label="20">链接</el-radio>
              <el-radio label="30">小程序</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            label="应用选择"
            prop="jumpPath"
            v-if="retrievalData.jumpType == 10"
          >
            <el-select
              v-model="retrievalData.jumpPath"
              placeholder="请选择应用"
              size="small"
              filterable
              clearable
            >
              <el-option
                v-for="item in innerList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="链接地址"
            prop="jumpPath"
            v-if="retrievalData.jumpType == 20"
          >
            <el-input v-model="retrievalData.jumpPath" size="small"></el-input>
          </el-form-item>
          <el-form-item
            label="小程序地址"
            prop="jumpPath"
            v-if="retrievalData.jumpType == 30"
          >
            <el-input v-model="retrievalData.jumpPath" size="small"></el-input>
          </el-form-item>
          <el-form-item class="czzjf">
            <el-button class="bgc-bv" @click="cancel" :disabled="false">取消</el-button>
            <el-button
              type="primary"
              class="bgc-bv"
              @click="addEdit('retrievalData')"
              >确定</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import UploadPic from "@/mixins/UploadPic";
export default {
  name: "operate/aeAdvisoryNotice",
  mixins: [UploadPic],
  data() {
    //路径校验
    let validateUrl = (rule, value, callback) => {
      if (this.retrievalData.jumpType == 20) {
        let reg =
          /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/g;
         var strRegex = "^((https|http|ftp|rtsp|mms)?://)" + "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?"          
 + "(([0-9]{1,3}\.){3}[0-9]{1,3}" // IP形式的URL- 199.194.52.184
  + "|" // 允许IP和DOMAIN（域名）
  + "([0-9a-z_!~*'()-]+\.)*" // 域名- www.
  + "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\." // 二级域名
  + "[a-z]{2,6})" // first level domain- .com or .museum
  + "(:[0-9]{1,4})?" // 端口- :80
  + "((/?)|" // a slash isn't required if there is no file name
  + "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
  var re = new RegExp(strRegex);
  console.log(re)
        if (value && !re.test(value) && !reg.test(value)) {
          callback(new Error("请输入带有http或https的正确链接地址"));
        } else {
          callback();
        }
      } else if (this.retrievalData.jumpType == 10) {
        if (value == "") {
          callback(new Error("请选择应用"));
        } else {
          callback();
        }
      } else if (this.retrievalData.jumpType == 30) {
        if (value == "") {
          callback(new Error("请输入跳转小程序链接地址"));
        } else {
          callback();
        }
      }
    };
    //通知类型校验
    let validateType = (rule, value, callback) => {
      if (this.retrievalData.noticeType == "20") {
        console.log(value);
        if (value == "") {
          callback(new Error("请上传导入文件"));
        } else {
          callback();
        }
      } else if (this.retrievalData.noticeType == "30") {
        if (value == "") {
          callback(new Error("请选择班级"));
        } else {
          callback();
        }
      } else if (this.retrievalData.noticeType == "40") {
        if (value == "") {
          callback(new Error("请选择机构"));
        } else {
          callback();
        }
      }
    };
    return {
      noticeTypeList: [
        //这期先不做区划的
        // {
        //     value:'10',
        //     label:'按行政区划',
        // },
        {
          value: "20",
          label: "按指定人",
        },
        {
          value: "30",
          label: "按班级",
        },
        {
          value: "40",
          label: "按机构",
        },
      ],
      CompanyList: [], //机构下拉数据
      application: [], //应用下拉数据
      innerList: [], //应用下拉数据
      // 通知数据
      retrievalData: {
        noticeTitle: "", //通知标题
        noticeContent: "", //通知内容
        noticeTime: "", //推送时间
        noticeType: "", //通知类型
        jumpType: "", //通知跳转类型
        jumpPath: "", //通知跳转路径
        compId: "", //机构Id
        projectId: "", //班级Id
        fileName: "", //文件名称
        noticeParam:'',
      },
      seaProjectCode: "",
      seaProjectName: "",
      projectList: [{}],
      // 校验输入
      rules: {
        noticeTitle: [
          { required: true, message: "请输入通知标题", trigger: "blur" },
        ],
        noticeContent: [
          { required: true, message: "请输入通知内容", trigger: "blur" },
        ],
        noticeType: [
          { required: true, message: "请选择通知范围", trigger: "change" },
        ],
        noticeParam: [
          { required: true, validator: validateType, trigger: "change" },
        ],
        // jumpType: [
        //   { required: true, message: "请选择跳转类型", trigger: "change" },
        // ],

        jumpPath: [{ required: false, validator: validateUrl, trigger: "blur" }],
      },
    };
  },
  mounted() {},
  computed: {},
  created() {
     this.superProjectSelect()
    this.getDictionary();
    if (this.$route.query.noticeId) {
      this.noticeId = this.$route.query.noticeId;
      this.getDeil();
    }
   
  },
  methods: {
    //班级名称
    superProjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.seaProjectName,
          projectCode: this.seaProjectCode,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.projectList = [{}];
        } else {
          res.data.list.map(el => {
            el.noticeParam = el.projectId
          })
          this.projectList = res.data.list;
          console.log(this.projectList)
        }
      });
    },
    /* 机构名称 */
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },

    getDictionary() {
      const APP_INNER_PATH_list = this.$setDictionary("APP_INNER_PATH", "list");
      const list = [];
      for (const key in APP_INNER_PATH_list) {
        list.push({
          value: key,
          label: APP_INNER_PATH_list[key],
        });
      }
      this.innerList = list;
    },
    //导入学员
    uploadChange(file) {
      this.retrievalData.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 1) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "TEMP");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.retrievalData.noticeParam = result.data.fileKey;
          // this.$refs["ruleForm"].clearValidate("fileName");
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
    },
    //删除文件
    reomveExl() {
      this.retrievalData.fileName = "";
      this.retrievalData.noticeParam = "";
      this.$forceUpdate();
    },
    //下载模版
    downTemplate() {
      this.$post("/msg/notice/userImport/template", {}).then((res) => {
        window.open(res.message);
      });
    },
    // 取消
    cancel() {
      this.$router.push({
        path: "/web/operate/pushList",
        query: {
          liuliuliu: true,
        },
      });
    },
    // 添加/编辑
    addEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let url = "/msg/notice/insert";
          let data = {
            noticeTitle: this.retrievalData.noticeTitle,
            noticeContent: this.retrievalData.noticeContent,
            noticeTime: this.retrievalData.noticeTime + ":00",
            noticeType: this.retrievalData.noticeType,
            noticeParam: this.retrievalData.noticeParam,
            jumpType: this.retrievalData.jumpType || null,
            jumpPath: this.retrievalData.jumpPath,
            fileName:this.retrievalData.fileName,
          };
          if (this.noticeId) {
            url = "/msg/notice/update";
            data.noticeId = this.noticeId;
          }
          this.$post(url, data).then((res) => {
            this.$message.success(res.message);
            this.$router.push({
              path: "/web/operate/pushList",
              query: {
                refresh: true,
              },
            });
          });
        }
      });
    },
    //点击编辑获取数据
    getDeil() {
      this.$post("/msg/notice/detail", {
        noticeId: this.$route.query.noticeId,
      })
        .then((res) => {
          if (res.status == "0") {
            const retrievalData = res.data || {};
            // = {
             this.retrievalData.noticeTitle= retrievalData.noticeTitle,
              this.retrievalData.noticeContent= retrievalData.noticeContent,
              this.retrievalData.noticeType= retrievalData.noticeType,
              this.retrievalData.jumpType= retrievalData.jumpType,
              this.retrievalData.jumpPath= retrievalData.jumpPath,
              console.log(retrievalData.noticeTime);
              this.retrievalData.noticeTime= retrievalData.noticeTime ? this.$moment(retrievalData.noticeTime).format(
                "YYYY-MM-DD HH:mm"
              ) :''
            // };
            if (retrievalData.noticeType == "20") {
              this.retrievalData.fileName = retrievalData.noticeParamName
               this.retrievalData.noticeParam = retrievalData.noticeParam;
            }
            if (retrievalData.noticeType == "30") {
              this.seaProjectName = retrievalData.noticeParamName;
               this.retrievalData.noticeParam = retrievalData.noticeParam;
              this.superProjectSelect();
              this.$forceUpdate();
             
            }
            if (retrievalData.noticeType == "40") {
              this.getCompany(retrievalData.noticeParam);
            }

            // this.rotationPath = this.baseform.rotationPath
          }

          // this.baseform.terminals = baseform.terminalStr;
          // this.baseform.thumbnail = baseform.picPath;
          // this.baseform.fileKey = baseform.pic;
          // this.baseform.rotationName = baseform.rotationName;
          // this.baseform.areaId = baseform.areaId;
          // this.baseform.sort = baseform.sort;
          // this.baseform.state = baseform.state;
          // this.baseform.rotationPath = baseform.rotationPath;
        })
        .catch(() => {});
    }, // //通知范围change
    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.CompanyList = [res.data];
      });
    },
    //通知打开change
    jumpTypeChange(val) {
      this.retrievalData.jumpPath = "";
      this.$refs["retrievalData"].clearValidate("jumpPath");
    },
    noticeTypeChange(val) {
       this.retrievalData.noticeParam = '';
         this.$refs["retrievalData"].clearValidate("noticeParam");
    }
  },
};
</script>

<style lang="less" scoped>
.el-form-item {
  margin-bottom: 18px;
}
.czzjf {
  width: 150px;
  margin: auto;
}
.czzjf /deep/ .el-form-item__content {
  margin-left: 0 !important;
}
.fileNameUpload {
  /deep/.upload-btn {
    height: 40px !important;
    .el-upload {
      height: 40px !important;
      border: none !important;
    }
  }
  /deep/ .el-form-item__content {
    display: flex;
  }
}
.noticeContent /deep/.el-textarea__inner {
  min-height: 200px !important;
  resize: none !important;
}
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  /deep/ .el-input__validateIcon {
    display: none ;
  }
}


</style>
